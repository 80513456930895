<template>
    <div>
         <b-tabs pills vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        nav-class="nav-left"
        nav-wrapper-class="col-md-3 col-12">
            <b-tab active>
                <template #title>
                <feather-icon
                    icon="UserIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">General</span>
                </template>
                <parkinglot-detail-general :general="parkinglot"></parkinglot-detail-general>
            </b-tab>
            <div v-if="parkinglot.complements.length > 0">
                <b-tab v-if="parkinglot.complements[0].warehouse != null">
                    <template #title>
                    <feather-icon
                        icon="BoxIcon"
                        size="18"
                        class="mr-50"/>
                    <span class="font-weight-bold">Bodega</span>
                    </template>
                    <parkinglot-detail-warehouse :warehouse="parkinglot.complements[0].warehouse"></parkinglot-detail-warehouse>
                </b-tab>
                <b-tab v-if="parkinglot.complements[0].parkinglot != null">
                    <template #title>
                    <feather-icon
                        icon="TruckIcon"
                        size="18"
                        class="mr-50"/>
                    <span class="font-weight-bold">Estacionamiento</span>
                    </template>
                    <parkinglot-detail-parkinglot :parkinglot="parkinglot.complements[0].parkinglot"></parkinglot-detail-parkinglot>
                </b-tab>
            </div>
        </b-tabs>
    </div>
</template>

<script>
    import ParkinglotDetailGeneral from './details/parkinglot/ParkinglotDetailGeneral'
    import ParkinglotDetailParkinglot from './details/parkinglot/ParkinglotDetailParkinglot'
    import ParkinglotDetailWarehouse from './details/parkinglot/ParkinglotDetailWarehouse'

    export default{
        name: 'ParkinglotDetailList',
        props: ['parkinglot'],
        components:{
            ParkinglotDetailGeneral,
            ParkinglotDetailParkinglot,
            ParkinglotDetailWarehouse
        },
        data() {
            return {
            }
        },
    }
</script>