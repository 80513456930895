<template>
    <div id="propeties-detail">
        <parkinglots-details-list :parkinglot="parkinglot"></parkinglots-details-list>
    </div>
</template>

<script>
    import ParkinglotsDetailsList from './components/ParkinglotsDetailsList'

    export default{
        name: 'ParkinglotDetails',
        components: {ParkinglotsDetailsList},
        props: ['parkinglot'],
        data() {
            return {

            }
        },
    }
</script>